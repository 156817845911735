var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "travio-center-container",
    { attrs: { "grid-width": "full" } },
    [
      _c("h2", { staticClass: "mb-4", staticStyle: { color: "#636363" } }, [
        _vm._v("Gift Vouchers"),
      ]),
      _c(
        "vx-card",
        [
          _c("div", { staticClass: "vx-row" }, [
            _c(
              "div",
              { staticClass: "vx-col w-full" },
              [
                _c(
                  "vs-button",
                  {
                    staticClass: "mr-4 sm:mb-0 mb-2",
                    on: { click: _vm.onAdd },
                  },
                  [_vm._v("Add")]
                ),
              ],
              1
            ),
          ]),
          _c("ag-grid-vue", {
            ref: "agGridTable",
            staticClass: "ag-theme-material mb-3",
            attrs: {
              components: _vm.cellRendererComponents,
              columnDefs: _vm.columnDefs,
              defaultColDef: _vm.defaultColDef,
              rowData: _vm.giftVouchers,
              gridOptions: _vm.gridOptions,
              context: _vm.context,
              domLayout: "autoHeight",
              rowSelection: "single",
              pagination: false,
              suppressPaginationPanel: true,
            },
            on: {
              "first-data-rendered": _vm.onFirstDataRendered,
              "grid-ready": _vm.onGridReady,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }